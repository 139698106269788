import React from "react";
import { useLocation, useNavigate } from "react-router-dom";
import styles from "./filterSortButton.module.css";
import { IoIosClose } from "react-icons/io";
import { certificationMap } from "../../../components/Utils/Helpers";

const FilterSortButton = ({ value, setQuery }) => {
  const location = useLocation();
  const navigate = useNavigate();
  // Skip certain keys
  if (
    value.key === "pageSize" ||
    value.key === "pageNumber" ||
    value.key === "unit" ||
    value.key === "category"
  ) {
    return null;
  }
const gwpkeyes = [
  "a1A3",
  "a1",
  "a2",
  "a3",
  "a4",
  "a5",
  "b1",
  "b2",
  "b3",
  "c1",
  "c2",
  "c3",
  "c4",
  "d",
  "safetyLevelMax"
];

const gwpKeyValues = {
  a1A3: "A1-A3",
  a1: "A1",
  a2: "A2",
  a3: "A3",
  a4: "A4",
  a5: "A5",
  b1: "B1",
  b2: "B2",
  b3: "B3",
  c1: "C1",
  c2: "C2",
  c3: "C3",
  c4: "C4",
  d: "D",
  safetyLevelMax: "Safety Level"
};

  const handleClick = () => {
    // Update the state query object
    setQuery((prev) => {
      const currentValue = prev[value.key];
      if (Array.isArray(currentValue)) {
        return {
          ...prev,
          [value.key]: currentValue.filter((item) => item !== value.value),
        };
      }
      return {
        ...prev,
        [value.key]: null,
      };
    });
     // Create a new URLSearchParams object from the current location's search string
    const searchParams = new URLSearchParams(location.search);
    // console.log("before",searchParams.toString());
    searchParams.delete(value.key,value.value); 
    // console.log("tried to delete",value.key,value.value);
    // console.log("after",searchParams.toString());


    // Update the URL
    navigate(`${location.pathname}?${searchParams.toString()}`);
  };
  const maxlength= (value, key) => {
    if (key === "validTo" || key === "safetyLevelMax") {
      return parseInt(value)
    }
    if (key === "certifications") {
      return certificationMap[value];
    }

    if(!isNaN(value) && key !== "certification"){
        return parseFloat(value).toExponential(1);
    }
      return value;
    }

  return (
    <div className={styles.filterSortButton}>
      <h3>
        {gwpkeyes.includes(value.key) ?  `${gwpKeyValues[value.key]}:`: null}  {maxlength(value.value, value.key)}
      </h3>
      <IoIosClose onClick={handleClick} className={styles.closeIcon} />
    </div>
  );
};

export default FilterSortButton;
