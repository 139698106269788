import React from 'react';
import styles from './styles/VisionPage.module.css'; // Import the CSS module

function VisionPage() {
  const paragraphs = [
    "BUILDTIVITY har visionen om at være byggebranchens svar på den grønne omstilling. Der skal skabes en fremtid, hvor miljø og den grønne omstilling er i fokus, hvor der ikke går på kompromis med formidling, brugervenlighed eller overskuelighed.",
    "Branchen skal samles i et fælles sprog omkring bæredygtighed og byggematerialer - BUILDTIVITY er det første skridt mod en samlet værdikæde. Dette kræver en ubesværet adgang til data og forklaringer, hvilket medfører styrkede beslutningstagere, bedre rådgivning og øget dokumentation. Det er en retning mod flere og grønnere byggerier i branchen.",
    "BUILDTIVITY skaber den nye standard inden for byggematerialer ved at forbinde, mennesker, viden og teknologi. Her er der fokus på den grønne omstilling, hvor vi vil være en katalysator for at gøre bæredygtighed tilgængelig og overkommelig for virksomheder i alle størrelser.",
    "Drømmen er en byggebranche, hvor der ikke kun overholdes kravene fra bygningsreglementet, men derimod en branche, der går forrest med klimaet. BUILDTIVITY er mere end bare en materialedatabase - Det er bidraget til en grønnere fremtid, et byggeri ad gangen."
  ];

  return (
    <div>
      <div className={styles.pageVisionpageStyle}>
        <h1 className={styles.headerStyle}>Vores vision</h1>
        <div className={styles.videoContainer}>
          <video className={styles.videobox} autoPlay loop muted>
            <source src="/images/Vision/video.mp4" type="video/mp4" />
          </video>
        </div>
        <div className={styles.boxContainer}>
          <div>
            {paragraphs.map((paragraph, index) => (
              <p key={index} className={styles.textsStyle}>{paragraph}</p>
            ))}
          </div>
          <div className={styles.imgbox}>
            <img src="/images/Vision/img1.svg" alt="1" className={styles.img1} />
            <img src="/images/Vision/img2.svg" alt="2" className={styles.img2} />
            <img src="/images/Vision/img3.svg" alt="3" className={styles.img3} />
          </div>
        </div>
      </div>
    </div>
  );
}

export default VisionPage;
