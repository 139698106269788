import { useParams, NavLink } from 'react-router-dom';
import { useEffect, useState } from 'react';
import { fetchItemData } from '../../components/APIS/categoryAPI';
import { getOneProjectbyId } from '../../components/APIS/postGetProject';
import './ProjectDetails.css';
import { IoReturnUpBack } from "react-icons/io5";
import DetailBox from '../Kategori/DetailBox';

function ProjectDetails() {
    const { projectId } = useParams();
    const [project, setProject] = useState(null);

    // Refreshes the project data
    const refreshProject = async () => {
        try {
            const projectData = await getOneProjectbyId(projectId);
            const itemDetailsPromises = projectData.productIds.map(item => fetchItemData(item.id));
            const itemsDetails = await Promise.all(itemDetailsPromises);
            const itemsWithDetails = itemsDetails.map((details, index) => ({
                id: projectData.productIds[index],
                data: details
            }));
            setProject({ ...projectData, items: itemsWithDetails });
            // console.log(project);
        } catch (error) {
            console.error(`Error fetching project with ID: ${projectId}`, error);
        }
    };

    // Fetch project data on component mount or when projectId changes
    useEffect(() => {
        refreshProject();
    }, []);

    // Function to get the content based on the project
    const getContent = () => {
        if (!project) {
            return <div style={{ color: "black" }}>Project not found</div>;
        }
        if (project.items.length === 0) {
            return (
                <div>
                    <p style={{ color: "black" }}>
                        Project has no items, find items on <NavLink to="/kategori">category page</NavLink>
                    </p>
                </div>
            );
        }

        // Group items by category
        const itemsByCategory = project.items.reduce((acc, item) => {
            const category = item.id.category;
            if (!acc[category]) acc[category] = [];
            acc[category].push(item);
            return acc;
        }, {});

        // Helper to get image URL
        const ImageItem = (item) => {
            if (
                item.data &&
                item.data.manualTypedData &&
                item.data.manualTypedData.producentUploadData &&
                Array.isArray(item.data.manualTypedData.producentUploadData.imageURLS) &&
                item.data.manualTypedData.producentUploadData.imageURLS.length > 0
            ) 
            {
                return item.data.manualTypedData.producentUploadData.imageURLS[0].url;
            }
            return ""; 
        };

        return Object.entries(itemsByCategory).map(([category, items]) => (
            <div key={category} className="category-group">
                <h2 style={{ color: "var(--Green)" }}>{category[0].toUpperCase() + category.slice(1)}</h2>
                {items.map((item, itemIndex) => (
                    console.log(item.data),
                    <DetailBox
                        handledel={refreshProject}
                        key={itemIndex}
                        itemid={item.data.id}
                        id={projectId}
                        Project={true}
                        data={item.data.manualTypedData}
                        billede_id={ImageItem(item)}
                        Unit={item.data.manualTypedData.gwpValues[0].gwpUnit}
                        category={category}
                    />
                ))}
            </div>
        ));
    };

    return (
        <div className='Proj-cont'>
            <div style={{display:'flex'}}>
            <NavLink to="/projekter" className="Proj-back"><IoReturnUpBack size={34} /></NavLink>
            <h1 className='h-proj'>{project?.projectName}</h1>
            </div>
            {getContent()}
        </div>
    );
}

export default ProjectDetails;
