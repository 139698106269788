import React from 'react';
import './loader.css'; // Your CSS for animations

const Loader = () => {
  // Get Length
  
  return (
    <div className="loader">
      <svg
        id="Lag_1"
        data-name="Lag_1"
        xmlns="http://www.w3.org/2000/svg"
        viewBox="0 0 507.9 534.19"
        width="200"
        height="200"
      >
        <g>
          <polygon
            className="cls-1 svg-elem-1"
            points="15.5 490.42 15.5 166.51 253.95 18.25 492.39 166.51 492.39 490.42 15.5 490.42"
          ></polygon>
          <path
            className="cls-3 svg-elem-2"
            d="m253.95,36.5l222.95,138.62v299.8H31V175.12L253.95,36.5m0-36.5L0,157.89v348.03h507.89V157.89L253.95,0h0Z"
          ></path>
        </g>
      </svg>
    </div>
  );
};

export default Loader;
