import React, { useState, useEffect } from 'react';


function MainPage() {

  const colorCodes = {
    Green: "#334C31",
    Sand: "#AE8C65",
    White: "#F5F5F7",
    Black: "#212426",
    Grey: "#D7D5D6"
  };

  const imageurls = [
    { url: './images/mainpage/img1.jpg' },
    { url: './images/mainpage/img2.jpg' },
    { url: './images/mainpage/img3.jpg' },
    { url: './images/mainpage/img4.jpg' },
  ];
  function hexToRgbA(hex, alpha){
    let r=0, g=0, b=0;
    
    if (hex.length === 4) {
        r = "0x" + hex[1] + hex[1];
        g = "0x" + hex[2] + hex[2];
        b = "0x" + hex[3] + hex[3];
    } else if (hex.length === 7) {
        r = "0x" + hex[1] + hex[2];
        g = "0x" + hex[3] + hex[4];
        b = "0x" + hex[5] + hex[6];
    }
    
    return "rgba("+ +r + "," + +g + "," + +b + "," + alpha + ")";
}

let bgColor = hexToRgbA(colorCodes.Grey, 0.85);



const ImageRenderer = () => {
  const styles = {
    imageContainer: {
      position: 'relative',
      maxHeight: '81vh',
      width: '100%',
      overflow: "hidden"
    },
  
    transitionImage: (opacity) => ({
      width: '100%',
      objectFit: 'cover',
      opacity: opacity,
      transition: 'opacity 2s ease', // smooth transition for opacity
      zIndex: -1,
    }),
  
    overlayTextbox: {
      width: '20vw',
      fontFamily: 'CenturyGothic',
      
      position: 'absolute',
      bottom: '0',
      left: '5vw',
      padding: '15px',
      borderTopLeftRadius: '15px',   // This curves the top left corner
      borderTopRightRadius: '15px',
      backgroundColor: bgColor,
      zIndex: 2, // set this to 2 to ensure it's always on top
    },  
  };
  const [currentImageIndex, setCurrentImageIndex] = useState(0);
  const [nextImageIndex, setNextImageIndex] = useState(1);
  const [currentOpacity, setCurrentOpacity] = useState(1);
  const [nextOpacity, setNextOpacity] = useState(0);

  const transitionDuration = 1100; // 1 second
  const imageDuration = 3000; // 3 seconds + 1s for transition = 4s total

  useEffect(() => {
      const handleTransition = () => {
          setCurrentOpacity(0);
          setNextOpacity(1);
          setTimeout(() => {
              setCurrentImageIndex(nextImageIndex);
              setNextImageIndex((nextImageIndex + 1) % imageurls.length);
              setCurrentOpacity(1);
              setNextOpacity(0);
          }, transitionDuration);
      };

      const interval = setInterval(handleTransition, imageDuration);
      return () => clearInterval(interval);
  }, [nextImageIndex]);

  const paragraphs = [
    "Buildtivity er byggebranchens svar på at sikre den grønne omstilling. Med stigende dokumentationskrav sikrer vi materialespecialister i SMV’er og optimerer arbejdsprocessen med 80%. Buildtivity sammenligner byggematerialer på CO2, Certificeringer og tekniske specifikationer, som skaber grøn vækst i bygge branchen."
  ];
  
  return (
      <div style={styles.imageContainer}>
          <img src={imageurls[currentImageIndex].url} alt="Current Description" style={{...styles.transitionImage(currentOpacity)}} />
          <img src={imageurls[nextImageIndex].url} alt="Next Description" style={{...styles.transitionImage(nextOpacity)}} />
          <div style={styles.overlayTextbox}>
              <img style={{width:'100%'} } src='./images/LongLogo.png' alt='Buildtivity'/>
              <p style={{color: colorCodes.Black, fontFamily:"Gothic"}}>{paragraphs[0]}</p>
          </div>
      </div>
  );
}


const Videotextrenderer = () => {
  const styles = {
      mainContainer: {
          display: 'flex',
          
          width: '100%',
          height: '40vh', // or whatever height you prefer
      },
      videoContainer: {
          flex: 1,
          display: 'flex',
          
      },
      textContainer: {
          flex: 1,
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'left',
          alignItems: 'flex-start', // aligns the text to the left
          paddingLeft: '20px', // some spacing
          maxWidth: '60vw', // or whatever width you prefer
          minWidth: '40vw',
          width: '100vw',
      },
      headerstyle: {
        display: 'flex',
        flexDirection: 'row',
        alignItems : 'center',
        fontSize: '1.5em',
        fontFamily: 'CenturyGothic',
        fontWeight: 'bold',
        
      },
      header: {
 
          color: colorCodes.Green,
      },
      smallHeader: {
        fontFamily: 'CenturyGothic',
        fontWeight: 'bold',
        color: colorCodes.Green,
      },
      textBlock: {
        color: colorCodes.Black,
        fontFamily: 'CenturyGothic',
        
      },
      textBlock2: {
        fontFamily: 'CenturyGothic',
        color: colorCodes.Black,
        fontWeight: 'bold',
        fontSize: '1.2em',
        },

      video: {
        width: '50vw',
        padding: '1%',
        borderRadius: '20px',
      },
  };
  const paragraphs = [
    "Udviklet med hjælp fra:",
    
    "Mikrolegat(Fonden for Entreprenørskab), Cyber Partners ApS.",
    "Karakter Arkitektur ApS, Lendager Group ApS, BK Nord A/S, Krabbe Vang Arkitekter A/S, Plex Arkitekter ApS, Keld og Johs A/S, Clear Invest, CAD-Tegnestuen ApS.",

    "Stor tak for den store opbakning og feedback, som vi har modtaget.",
    ];
  return (
      <div style={styles.mainContainer}>
          <div style={styles.textContainer}>
            <div style={styles.headerstyle}>
              {/* <h1 style={styles.header}>Buildtivity</h1>
              <h3 style={styles.smallHeader}>- Creating Material Overview</h3> */}
            </div>
            <div>


              <p style={styles.textBlock2}> {paragraphs[0]} </p>
              <p style={styles.textBlock}> {paragraphs[1]} </p>
              <p style={styles.textBlock}> {paragraphs[2]} </p>
              <p style={styles.textBlock}> {paragraphs[3]} </p>
            </div>
              
              
              {/* <div>
                    Se de forskellige abonnementer <Link to="/Subscription">her</Link>.
                </div> */}
            </div>
          <div style={styles.videoContainer}>
              <video style={styles.video} controls muted>
                  <source src="path_to_video.mp4" type="video/mp4" />
                  Your browser does not support the video tag.
              </video>
          </div>
          
      </div>
  );
};






  



  return (
    <div >
      <ImageRenderer />
      <Videotextrenderer />
    </div>
  );
}

export default MainPage;
